import { EventMesh } from './EventMesh'
import { SGeom } from './SGeom'
import {
    AssetManager,
    Geometry,
    Color,
    Points,
    Texture,
    PointsMaterial,
    PlaneBufferGeometry,
    LinearFilter,
    Vector3
} from './ImportHelper'

let cnv
let _defaultTexture
let _defaultTexturePlane
function addDefaultTexture (style) {
    let size = 16
    if (!cnv) {
        cnv = document.createElement('canvas')
        cnv.width = size
        cnv.height = size
        let ctx = cnv.getContext('2d')
        ctx.fillStyle = '#ffffff'
        ctx.fillRect(0, 0, size, size)
        cnv.style.top = '0px'
    }

    if (!_defaultTexture) {
        _defaultTexture = new Texture(cnv)
    }

    if (!_defaultTexturePlane) {
        _defaultTexturePlane = new PlaneBufferGeometry(size, size, 1, 1)
    }

    style.texture = _defaultTexture
    style.xoffset = 0
    style.yoffset = 0
    style.geometry = _defaultTexturePlane
    return style
}

class SMultiPoint extends SGeom {
    constructor (scalable, geomDataHolder) {
        super(scalable, geomDataHolder)
        this.type = 'MultiPoint'
        this.softText = scalable.softText
        if (!SMultiPoint.__hookTexture) {
            var t = AssetManager.getInstance().GetAssetNoCache('img/scalable/hook.jpg')
            t.generateMipmaps = false
            t.minFilter = LinearFilter
            SMultiPoint.__hookTexture = t
        }

        if (!SMultiPoint.__hookGeom) {
            var geo = new Geometry()
            var vertex = new Vector3()
            geo.vertices.push(vertex)
            SMultiPoint.__hookGeom = geo
        }

        var pm = new PointsMaterial({ size: 8, sizeAttenuation: false, map: SMultiPoint.__hookTexture, depthTest: true, depthWrite: true, transparent: true })
        this.hookMaterial = pm
        this.iconGeom = SMultiPoint.__iconGeom
        this.hookGeom = SMultiPoint.__hookGeom
        this.scalable = scalable
    }

    reset () {
        this.update()
        this.lookAt(new Vector3(0, this.position.y, 0))
    }

    onClick (e) {
        e.currentTarget = this
        this.throwEvent(e)
    }

    onSelect () {
        this.icon.material.color = new Color(0x00ffff)
    }

    setPoints (points) {
        if (points.length === 1) {
            var p = points[0]
            this.setLocation(p.lon, p.lat, p.alt)
            this.reset()
        } else {
            console.warn('SMultiPoint doesn\'t multi point input!')
        }
    }

    stopDraw () {
        this.update()
    }

    setDynamicPoint () { }

    setData (points, atts) {
        this.attributes = atts
        if (points.length === 1) {
            var p = points[0]
            this.setLocation(p.lon, p.lat, p.alt)
            this.visible = true
        } else {
            console.warn('SMultiPoint doesn\'t multi point input!')
            // this.visible = false
        }
    }

    onDeSelect () {
        this.icon.material.color = new Color(0xffffff)
    }

    enable (panogl, renderScene) {
        this.panogl = panogl
        if (!this.enabled) {
            var t = this
            if (!t.icon) {
                this.hookMesh = new Points(this.hookGeom, this.hookMaterial)
                this.add(this.hookMesh)
            }
        }
    }

    updateStyle () {
        if (this.__layer) {
            if (this.icon) {
                this.icon.material.dispose()
                this.icon.setClickable(false)
                if (this.icon.parent) {
                    this.icon.parent.remove(this.icon)
                }
            }

            if (this.hookMesh) {
                this.hookMesh.material.opacity = this.getOpacity()
            }

            var styleObject = this.__layer.getStyle(this)

            if (!styleObject.texture) {
                addDefaultTexture(styleObject)
            }

            var texture = styleObject.texture
            if (texture) {
                texture.needsUpdate = true
                var geom = styleObject.geometry
                var material = new THREE.MeshBasicMaterial({ map: texture, color: 0xffffff, transparent: true, alphaTest: 0.2 })
                // var material = new THREE.MeshBasicMaterial({ map: texture })
                this.icon = new EventMesh(geom, material)
                this.icon.material.opacity = this.getOpacity()
                this.add(this.icon)
                var panogl = this.panogl
                var cam = panogl.getMainCamera()
                this.icon.setClickable(true, panogl, null, cam)
                this.icon.position.set(styleObject.xoffset, styleObject.yoffset, -1)
                this.icon.addEvent(SGeom.CLICK, this, this.onClick)
                this.icon.addEvent(SGeom.MOUSE_DOWN, this, this.onDown)
            }
        }
    }

    update () {
        if (this.lon != null && this.lat != null) {
            this.setLocation(this.lon, this.lat, this.alt)
            this.lookAt(new Vector3(0, this.position.y, 0))
            this.setLabel()
        }
    }

    setLabel () {
        let softText = this.softText
        if (!this.__layer || this.__layer.getLabelVisibility()) {
            if (!this.labelHook) {
                this.labelHook = new THREE.Object3D()
                this.add(this.labelHook)
                var cam = this.panogl.getMainCamera()
                var alt = isNaN(this.alt) || !this.alt ? '0' : this.alt.toFixed(6)

                var commonGeom = this._commonGeom
                commonGeom.attributes['_lon'] = this.lon
                commonGeom.attributes['_lat'] = this.lat
                commonGeom.attributes['_alt'] = alt

                var text = this.getLabelText()
                softText && softText.addObject3D(this.labelHook, cam, text)
            }
        } else {
            if (this.labelHook) {
                softText && softText.removeObject3D(this.labelHook)
                this.remove(this.labelHook)
                this.labelHook = null
            }
        }
    }

    setLocation (lon, lat, alt) {
        this.lat = lat
        this.lon = lon
        this.alt = alt
        var pos = this.scalable.calculatePointPositionFromLonLatAlt(lon, lat, alt)

        var altFlat = isNaN(this.alt) || !this.alt ? '0' : this.alt.toFixed(6)
        this.attributes.___location = 'lon:' + this.lon.toFixed(6) + ' lat:' + this.lat.toFixed(6) + ' alt:' + this.alt

        this.points[0] = { lon: lon, lat: lat, alt: altFlat }
        this.position.set(pos.x, pos.y, pos.z)
    }

    disableAll () {
        if (this.icon) {
            this.icon.setClickable(false)
        }

        if (this.parent) {
            this.parent.remove(this)
        }
        this.enabled = false
    }

    destroy () {
        this.disableAll()

        if (this.icon) {
            if (this.icon.geometry) {
                this.icon.geometry.dispose()
            }

            if (this.icon.material) {
                this.icon.material.dispose()
            }
        }

        if (this.hookMesh) {
            if (this.hookMesh.material) {
                this.hookMesh.material.dispose()
            }
        }

        if (this.labelHook) {
            let softText = this.softText
            softText && softText.removeObject3D(this.labelHook)
            this.softText = null
            this.labelHook = null
        }
    }
}

SMultiPoint.isMultiGeom = true

export { SMultiPoint }
