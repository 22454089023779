import {Layer} from './Layer'

function ArcGISFeatureLayer (name, serviceURL, token, keyID, geomType, style) {
    this.serviceURL = serviceURL
    Layer.apply(this, [name, keyID, geomType, style])
    this.setLabelTextSample('#' + keyID)
    this.token = token
    var infoURL = serviceURL + '?f=json'

    if (token) {
        infoURL += '&token=' + token
    }

    if (Layer.__instances[this.__layerID].length > 1 && Layer.__instances[this.__layerID][0].layerInfo) {
        this.setParameters(Layer.__instances[this.__layerID][0].layerInfo)
    } else {
        var t = this

        var ajaxObject = $.ajax({
            url: infoURL,
            dataType: 'json'
        })

        ajaxObject.done(function (e) {
            t.setParameters(e)
        })

        ajaxObject.fail(function (e) {
            console.log(e)
        })
    }
}

ArcGISFeatureLayer.prototype = Object.assign(Object.create(Layer.prototype),
    {
        constructor: ArcGISFeatureLayer,
        setParameters: function (e) {
            if (e.error) {
                console.log(e)
                return
            }

            var d = e
            this.layerInfo = e
            this.__keyID = d.objectIdField

            if (d.geometryType === 'esriGeometryPoint') {
                this.__typeClass = AnkaScalable.SPoint
            } else if (d.geometryType === 'esriGeometryPolyline') {
                this.__typeClass = AnkaScalable.SMultiLine
            } else if (d.geometryType === 'esriGeometryPolygon') {
                this.__typeClass = AnkaScalable.SMultiPolygon
            }

            var drawingInfo = d.drawingInfo
            var renderer = drawingInfo.renderer

            var uniqValueInfo = renderer.uniqueValueInfos

            if (renderer.type === 'simple') {
                let image = document.createElement('img')
                let symbol = renderer.symbol
                let o = {}

                if (symbol.imageData) {
                    o.width = symbol.width
                    o.height = symbol.height
                    o.xoffset = symbol.xoffset
                    o.yoffset = symbol.yoffset
                    let tm = new THREE.Texture(image)
                    tm.minFilter = THREE.LinearFilter
                    image.src = 'data:' + symbol.contentType + ';base64,' + symbol.imageData
                    tm.needsUpdate = true
                    o.img = image
                    let geom = new THREE.PlaneBufferGeometry(o.width, o.height, 1, 1)
                    o.geometry = geom
                    o.texture = tm

                    this.defaultSymbol = o
                } else {
                    if (this.__typeClass === AnkaScalable.SMultiLine) {
                        let style = this.__style
                        style.lineWidth = symbol.width
                        let c = new THREE.Color(symbol.color[0] / 255, symbol.color[1] / 255, symbol.color[2] / 255)
                        style.lineColor = c.getHex()
                        style.lineOpacity = symbol.color[3]
                    } else {
                        let style = this.__style
                        let c = new THREE.Color(symbol.color[0] / 255, symbol.color[1] / 255, symbol.color[2] / 255)
                        style.fillColor = c.getHex()
                        style.fillOpacity = symbol.color[3]
                        style.lineWidth = symbol.outline.width
                        var lineColor = new THREE.Color(symbol.outline.color[0] / 255, symbol.outline.color[1] / 255, symbol.outline.color[2] / 255)
                        style.lineColor = lineColor.getHex()
                        style.lineOpacity = symbol.outline.color[3]
                    }
                }
            } else if (uniqValueInfo) {
                if (renderer.field1 != null) {
                    this.styleField = renderer.field1
                }

                this.__icons = {}
                for (var i = 0; i < uniqValueInfo.length; i++) {
                    var uv = uniqValueInfo[i]
                    let o = {}
                    if (uv.symbol.color) {
                        var colorAr = uv.symbol.color
                        o.lineColor = new THREE.Color(parseFloat(colorAr[0]) / 255, parseFloat(colorAr[1]) / 255, parseFloat(colorAr[2]) / 255)
                        o.width = uv.symbol.width
                        o.lineOpacity = colorAr[3] / 255
                    } else {
                        o.width = uv.symbol.width
                        o.height = uv.symbol.height
                        o.xoffset = uv.symbol.xoffset
                        o.yoffset = uv.symbol.yoffset
                        let image = document.createElement('img')
                        let tm = new THREE.Texture(image)
                        tm.minFilter = THREE.LinearFilter
                        image.src = 'data:' + uv.symbol.contentType + ';base64,' + uv.symbol.imageData
                        tm.needsUpdate = true
                        o.img = image
                        let geom = new THREE.PlaneBufferGeometry(o.width, o.height, 1, 1)
                        o.geometry = geom
                        o.texture = tm
                    }

                    this.__icons[uv.value] = o
                }
            }

            this.renderer = renderer

            this.getCurrentData()
        },

        getStyle: function (geom) {
            if (geom && ((this.styleField != null && geom.attributes) || (this.defaultSymbol !== undefined))) {
                if (this.renderer.type === 'simple') {
                    return this.defaultSymbol
                } else {
                    if (this.styleField != null) {
                        var uniqueValue = geom.attributes[this.styleField]
                        if (this.__icons[uniqueValue]) {
                            return this.__icons[uniqueValue]
                        } else {
                            return this.__style
                        }
                    } else {
                        return this.__style
                    }
                }
            } else {
                // if()
                return this.__style
            }
        },

        getCurrentData: function () {
            if (!this.scalable) {
                return
            }

            var currentPoint = this.scalable.baseObject.getCurrentPoint()

            if (!currentPoint) {
                this.__isWaitingForRequestData = true
                return
            }

            var url = this.serviceURL

            var query = url + '/query?f=json'
            var returnGeom = query + '&returnGeometry=true'
            var point = returnGeom + '&geometry="POINT": {"x": ' + currentPoint.lon + ',"y": ' + currentPoint.lat + '}"'
            // var point = returnGeom + '&geometry="POINT": {"x": 511991.2298999997,"y": 4239636.3451000005}"';
            var geomType = point + '&geometryType=esriGeometryPoint'
            var inSR = geomType + '&inSR=4326'
            var intect = inSR + '&spatialRel=esriSpatialRelIntersects'
            var distance = intect + '&distance=30'
            var unit = distance + '&units=esriSRUnit_Meter'
            var outFields = unit + '&outFields=*'
            var outSR = outFields + '&outSR=4326'
            var returnDist = outSR + '&returnDistinctValues=false'
            var final = returnDist + '&returnZ=false&returnM=false' + '&token=' + this.token

            var ajaxCall = $.ajax({
                url: final,
                dataType: 'json'
            })

            var t = this
            ajaxCall.done(function (e) {
                t.createGeometries(e)
                t.__isWaitingForRequestData = false
            })

            ajaxCall.fail(function (e) {
                console.log(e)
            })
        },

        createGeometries: function (e) {
            var cloneGeoms = this.getGeomListClone()
            this.resetGeomList()
            if (e) {
                if (e.features) {
                    for (var i = 0; i < e.features.length; i++) {
                        var feature = e.features[i]
                        var attributes = feature.attributes
                        var geometry = feature.geometry

                        if (this.__typeClass.isMultiGeom) {
                            if (this.__typeClass === AnkaScalable.SMultiLine) {
                                this.createPolyLine(geometry, attributes)
                            } else if (this.__typeClass === AnkaScalable.SMultiPolygon) {
                                this.createMultiPolygon(geometry, attributes)
                            }
                        } else {
                            var pt = {lon: geometry.x, lat: geometry.y}
                            var gdh = new AnkaScalable.GeomDataHolder(this.__typeClass, [pt], attributes)
                            gdh.setStatus(AnkaScalable.GeomDataHolder.STATUS.COMPLETED)
                            this.addToList(gdh)
                        }
                    }
                }
            }

            this.removeGeomDatas(cloneGeoms)
            Layer.prototype.redraw.apply(this, [true])
        },

        addToList: function (geom) {
            if (!geom.layer) {
                geom.layer = this
            }

            if (geom.getKeyValue(this.__keyID) === undefined) {
                geom.attributes[this.__keyID] = geom.uniqueID
            }

            if (geom.isDrawnData) {
                this.addToDrawingList(geom)
            } else {
                Layer.prototype.addToList.apply(this, [geom])
            }
        },

        createMultiPolygon: function (geometry, attributes) {
            var group = []
            var rings = geometry.rings

            for (var j = 0; j < rings.length; j++) {
                group[j] = []
                for (var k = 0; k < rings[j].length; k++) {
                    var p = rings[j][k]
                    var x = p[0]
                    var y = p[1]
                    var po = {lon: x, lat: y}

                    if (p.length > 2) {
                        po.alt = p[2]
                    } else {
                        po.alt = NaN
                    }
                    group[j].push(po)
                }
            }

            var gdh = new AnkaScalable.GeomDataHolder(this.__typeClass, group, attributes)
            gdh.setStatus(AnkaScalable.GeomDataHolder.STATUS.COMPLETED)
            this.addToList(gdh)
        },

        createPolyLine: function (geometry, attributes) {
            var group = []
            var paths = geometry.paths
            for (var j = 0; j < paths.length; j++) {
                group[j] = []
                for (var k = 0; k < paths[j].length; k++) {
                    var p = paths[j][k]
                    var x = p[0]
                    var y = p[1]
                    var po = {lon: x, lat: y}

                    if (p.length > 2) {
                        po.alt = p[2]
                    } else {
                        po.alt = NaN
                    }
                    group[j].push(po)
                }
            }

            var gdh = new AnkaScalable.GeomDataHolder(this.__typeClass, group, attributes)
            gdh.setStatus(AnkaScalable.GeomDataHolder.STATUS.COMPLETED)
            this.addToList(gdh)
        },

        redraw: function (refreshFromSource) {
            if (this.visible) {
                this.removeAllChildren()
                if (this.layerInfo) {
                    this.getCurrentData()
                } else {
                    Layer.prototype.redraw.apply(this, [refreshFromSource])
                }
            }
        }

    })

export {ArcGISFeatureLayer}
