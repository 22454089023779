import { SGeom } from './SGeom'
import { EventLine } from './EventLine'
import { PathGeometry } from './PathGeometry'




let type = 'MultiLine'
class SMultiLine extends SGeom {
    constructor(scalable, geomdata) {
        super(undefined, geomdata)

        this.groundLabel = null

        this._lines = []
        this._meshLines = []
        this._clickMeshes = []
        this.canDraw = true
        this._scalable = scalable
        this.softText = scalable.softText
        this.labelHook = new THREE.Object3D()
        this.material = new THREE.LineMaterial({
            color: 0xff00ff,
            linewidth: 5, // in pixels
            dashed: true,
            depthTest: true,
            transparent: true,
            depthWrite: false
        })

        this._vertexPointMaterial = new THREE.PointsMaterial({
            color: 0xffffff,
            size: 5,
            opacity: 0.1,
            transparent: true,
            depthWrite: false,
            depthTest: false,
            sizeAttenuation: false
        })

        scalable.getBasePano().addResizeMaterial(this.material)
    }

    get type() {
        return type
    }

    enable(panogl) {
        if (!this.enabled) {
            this.openGeom = true
            this.panogl = panogl
            this.canDraw = true
        }
    }

    stopDraw() {
        this.openGeom = false
        this.dynamicPoint = null
        this.setLabel()
    }

    setLabel() {
        if (!this.__layer || this.__layer.getLabelVisibility()) {
            if (!this.openGeom) {
                let total = 0

                for (let i = 0; i < this._lines.length; i++) {
                    let line = this._lines[i]
                    let lineTotal = 0

                    for (let j = 0; j < line.length - 1; j++) {
                        let p1 = line[j]
                        let p2 = line[j + 1]
                        let length = AnkaPanAPI.Utils.haversine(p1.lat, p2.lat, p1.lon, p2.lon)
                        let p1Alt = isNaN(p1.alt) || p1.alt === undefined || p1.alt == null ? 0 : p1.alt
                        let p2Alt = isNaN(p2.alt) || p2.alt === undefined || p2.alt == null ? 0 : p2.alt

                        if (p1Alt !== p2Alt) {
                            var opposite = p1Alt - p2Alt
                            length = Math.sqrt((opposite * opposite) + (length * length))
                        }
                        lineTotal += length
                    }

                    total += lineTotal
                }

                var c = this.getCentroid()
                var cam = this.panogl.getMainCamera()
                this.labelHook.position.set(c.x, c.y, c.z)
                this.add(this.labelHook)
                this._commonGeom.attributes['_length'] = (Math.round(total * 100) / 100) + 'm'

                if (this.__layer.isLabelOnLine) {
                    this.createGroundLabel(this.getLabelText())
                } else {
                    const label = this.getLabelText()
                    this.softText && this.softText.addObject3D(this.labelHook, cam, label)
                }
            }
        } else {
            if (this.labelHook) {
                this.softText && this.softText.removeObject3D(this.labelHook)
            }
        }
    }

    createGroundLabel(text) {
        let catArray = []
        for (let i = 0; i < this._lines.length; i++) {
            for (var j = 0; j < this._lines[i].length; j++) {
                var p = this._lines[i][j]
                var pp = this._scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)
                catArray.push(new THREE.Vector3(pp.x, pp.y, pp.z))
            }
        }

        let textureObj = AnkaPanAPI.Utils.createTextTextureForGround(text)
        let texture = textureObj.texture
        texture.generateMipmaps = false
        texture.minFilter = THREE.LinearFilter
        texture.magFilter = THREE.LinearFilter
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping

        let mati = new THREE.MeshBasicMaterial({ map: texture, transparent: true, alphaTest: 0, depthWrite: false, depthTest: false })
        let ge = new PathGeometry(catArray)
        this.groundLabel = new THREE.Mesh(ge, mati)
        this.add(this.groundLabel)
    }

    getCentroid() {
        var sp = []

        for (let i = 0; i < this._lines.length; i++) {
            var line = this._lines[i]
            sp = sp.concat(line)
        }

        var minX = 500000000
        var minY = 500000000
        var minZ = 500000000
        var maxX = -500000000
        var maxY = -500000000
        var maxZ = -500000000

        for (let i = 0; i < sp.length; i++) {
            var p = sp[i]
            var pp = this._scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)

            if (pp.x < minX) { minX = pp.x }

            if (pp.x > maxX) { maxX = pp.x }

            if (pp.z < minZ) { minZ = pp.z }

            if (pp.z > maxZ) { maxZ = pp.z }

            if (pp.y < minY) { minY = pp.y }

            if (pp.y > maxY) { maxY = pp.y }
        }

        var x = minX + ((maxX - minX) / 2)
        var z = minZ + ((maxZ - minZ) / 2)
        var y = minY + ((maxY - minY) / 2)

        return { x: x, y: y, z: z }
    }

    onClick(e) {
        e.currentTarget = this
        this.throwEvent(e)
    }

    setData(lines, atts) {
        this.attributes = atts
        var allLines = []
        for (var i = 0; i < lines.length; i++) {
            var pts = lines[i]
            var points = []
            for (var j = 0; j < pts.length; j++) {
                points.push(pts[j])
            }
            allLines.push(points)
        }
        this._lines = allLines
    }

    updateStyle() {
        if (this.__layer) {
            var style = this.__layer.getStyle(this)
            this.material.color = new THREE.Color(style.lineColor)

            let opacity = style.lineOpacity * this.getOpacity();
            if (isNaN(opacity)) {
                opacity = 1;
            }

            this._vertexPointMaterial.opacity = opacity;
            this.material.opacity = opacity;
            this.material.linewidth = style.lineWidth
        }
    }

    onSelect() {
        this.material.color = new THREE.Color(0, 1, 1)
    }

    onDeSelect() {
        this.updateStyle()
    }

    destroyMeshes() {
        let meshes = this._meshLines
        for (let i = 0; i < meshes.length; i++) {
            const line = meshes[i]
            line.geometry.dispose()
            this.remove(line)
        }
        meshes.length = 0
    }

    // TODO wrong naming
    createIntersecyLines() {
        this.destroyClickMeshes()
        let vertices = this._vertices
        for (let i = 0; i < vertices.length; i++) {
            const vertex = vertices[i]
            var geom = new THREE.BufferGeometry()
            var positions = new Float32Array(vertex)
            geom.addAttribute('position', new THREE.BufferAttribute(positions, 3))

            let material = new THREE.LineBasicMaterial({
                color: new THREE.Color(1, 1, 0),
                linewidth: 1,
                depthTest: true,
                opacity: 0,
                transparent: true,
                depthWrite: false
            })

            var lineObject = new EventLine(geom, material)
            lineObject.name = 'SMultiLine_çizim'
            this.add(lineObject)
            var camera = this.panogl.getMainCamera()
            lineObject.setClickable(true, this.panogl, null, camera)
            lineObject.addEvent(SGeom.CLICK, this, this.onClick)
            lineObject.addEvent(SGeom.MOUSE_DOWN, this, this.onDown)
            this._clickMeshes.push(lineObject)
        }
    }

    update() {

        if (this.vertexPoints) {
            this.remove(this.vertexPoints)
            this.vertexPoints.geometry.dispose()
        }

        this.position.set(0, 0, 0)
        for (let i = 0; i < this._meshLines.length; i++) {
            const mesh = this._meshLines[i]
            mesh.geometry.dispose()
            this.remove(mesh)
        }

        this._vertices = []
        let lines = this._lines
        for (let i = 0; i < lines.length; i++) {
            const lineData = lines[i]
            let vertices = []
            for (let j = 0; j < lineData.length; j++) {
                const nodePoint = lineData[j]
                let cartesianPt = this._scalable.calculatePointPositionFromLonLatAlt(nodePoint.lon, nodePoint.lat, nodePoint.alt)
                vertices.push(cartesianPt.x, cartesianPt.y, cartesianPt.z)
            }
            this._vertices.push(vertices)
            let geom = new THREE.LineGeometry()
            let line = new THREE.Line2(geom, this.material)
            line.name = 'SMultiLine_' + this.uid
            line.frustumCulled = false
            line.geometry.setPositions(vertices)
            line.computeLineDistances()
            this.add(line)
            this._meshLines.push(line)
            this.material.resolution.set(window.innerWidth, window.innerHeight)
        }


        let allVertices = this._vertices.reduce((acc, cur) => {
            return cur.concat(acc)
        }, [])

        if (allVertices.length > 0) {
            let vertexGeom = new THREE.BufferGeometry()
            vertexGeom.addAttribute('position', new THREE.BufferAttribute(new Float32Array(allVertices), 3))
            let vertexPoints = new THREE.Points(vertexGeom, this._vertexPointMaterial)
            this.vertexPoints = vertexPoints
            this.add(vertexPoints)
        }


        this.createIntersecyLines()
        this.setLabel()
    }

    disableAll() {
        if (this.parent) {
            this.parent.remove(this)
        }
        this.enabled = false
    }

    destroyClickMeshes() {
        let clickMeshes = this._clickMeshes
        for (let i = 0; i < clickMeshes.length; i++) {
            const clickMesh = clickMeshes[i]
            clickMesh.geometry.dispose()
            clickMesh.material.dispose()
            clickMesh.setClickable(false)
            this.remove(clickMesh)
        }
    }

    destroy() {
        this.disableAll()

        this.destroyClickMeshes()

        let meshes = this._meshLines

        for (let i = 0; i < meshes.length; i++) {
            const mesh = meshes[i]
            mesh.geometry.dispose()
            mesh.material.dispose()
            this.remove(mesh)
        }

        let vertexPoints = this.vertexPoints;
        if (vertexPoints) {
            vertexPoints.geometry.dispose();
            vertexPoints.material.dispose();
            this.remove(this.vertexPoints);
        }

        this._scalable.getBasePano().removeResizeMaterial(this.material)

        this.material.dispose()

        if (this.groundLabel) {
            this.groundLabel.geometry.dispose()
            this.groundLabel.material.map.dispose()
            this.groundLabel.material.dispose()
        }

        if (this.material) {
            this.material.dispose()
        }

        if (this.softText && this.labelHook) {
            this.softText.removeObject3D(this.labelHook)
        }
    }
}

SMultiLine.isMultiGeom = true

export { SMultiLine }
