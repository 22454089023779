
EventLine.prototype = Object.create(THREE.Line.prototype)
var mtpt4234ds = Object.create(AnkaPanAPI.Base.prototype)
for (var str4234432 in mtpt4234ds) { EventLine.prototype[str4234432] = mtpt4234ds[str4234432] }
EventLine.prototype.constructor = EventLine

function EventLine () {
    AnkaPanAPI.Base.apply(this, [])
    THREE.Line.apply(this, arguments)
    this.userData.clickPriority = 1
}
export { EventLine }
