import { Base } from './ImportHelper'
const GeometryObserverEvents = {
    NEW_GEOMETRIES: 'onNewGeometriesAdd',
    GEOMETRY_REMOVE: 'onGeomRemove'
}

let _geometryList = []
let allowInheritance = true
class GeometryObserverClass extends Base {
    constructor () {
        super()
        if (!allowInheritance) {
            throw new Error('GeometryObserver instance cannot be created more than once')
        }
        allowInheritance = false
    }

    addGeometries (geometries) {
        if (Array.isArray(geometries)) {
            let newGeoms = []
            geometries.forEach(element => {
                if (_geometryList.indexOf(element) === -1) {
                    newGeoms.push(element)
                    _geometryList.push(element)
                }
            })

            if (newGeoms.length > 0) {
                this.throwEvent({ type: GeometryObserverEvents.NEW_GEOMETRIES, geometries: newGeoms })
            }
        } else {
            throw new Error('Parameter can be only Array<GeomDataHolder>')
        }
    }

    removeGeometry (gdh) {
        let index = _geometryList.indexOf(gdh)
        if (index > -1) {
            let gdh = _geometryList.splice(index, 1)[0]
            this.throwEvent({ type: GeometryObserverEvents.GEOMETRY_REMOVE, target: gdh })
        }
    }
}

const GeometryObserver = new GeometryObserverClass()
export { GeometryObserver, GeometryObserverEvents }
