import { GeomDataHolder } from './GeomDataHolder'

SGeom.prototype = Object.create(THREE.Object3D.prototype)
var mtpt = Object.create(AnkaPanAPI.Base.prototype)
for (var str in mtpt) { SGeom.prototype[str] = mtpt[str] }
SGeom.prototype.constructor = SGeom

SGeom.CLICK = 'click'
SGeom.MOUSE_DOWN = 'onMouseDown'

function SGeom (geom, commonGeom) {
    this._isSelected = false
    this.points = []
    this.attributes = {}
    this.userData = {}
    AnkaPanAPI.Base.apply(this, [])
    THREE.Object3D.apply(this, arguments)
    this.setCommonGeom(commonGeom)
}

SGeom.prototype.updateByGeom = function (geomDataHolder) {
    console.log('updateByGeom is not implemented')
}

SGeom.prototype.updateStyle = function (geomDataHolder) {
    console.log('updateStyle is not implemented')
}

SGeom.prototype.getOpacity = function () {
    if (this.__layer) {
        return this.__layer.getOpacity()
    }
    return 1
}

SGeom.prototype.setCommonGeom = function (geom) {
    if (geom instanceof GeomDataHolder) {
        this._commonGeom = geom
    } else {
        console.error('Cannot be created without commongeom')
    }
}

SGeom.prototype.getLabelText = function () {
    if (this.__layer && !this.__layer.isSketchLayer) {
        return this.__layer.getLabelText(this)
    } else {
        if (this.__layer) {
            var text = this.__layer.getLabelText(this)
            if (text != null && text.length > 0) {
                return text
            }
        }
        var cg = this._commonGeom
        if (cg.type === GeomDataHolder.POINT) {

            if(cg.attributes) {
                let atts = cg.attributes
                if(atts._lon !== undefined) {
                    let lon = parseFloat(atts._lon).toFixed(6)
                    let lat = parseFloat(atts._lat).toFixed(6)
                    let alt = parseFloat(atts._alt).toFixed(3)
                    return lon + ', ' + lat + ', ' + alt
                }
               
            }

        } else if (cg.type === GeomDataHolder.LINE) {
            return cg.attributes['_length']
        } else if (cg.type === GeomDataHolder.POLYGON) {
            return cg.attributes['_areaSize']
        } else {
            if (cg.attributes.hasOwnProperty('_length')) {
                return cg.attributes['_length']
            } else {
                console.log('Unnown type')
            }
        }
    }
}

SGeom.prototype.getCommonGeom = function () {
    return this._commonGeom
}

SGeom.prototype.onDown = function (e) {
    e.currentTarget = this
    this.throwEvent(e)
}

SGeom.prototype.update = function () {
    console.log('Need to be override')
}

SGeom.prototype.getAttributes = function () {
    return this.attributes
}

SGeom.prototype.isSelected = function () {
    return this._isSelected
}

SGeom.prototype.setLayer = function (lyr) {
    this.__layer = lyr
}

SGeom.prototype.destroy = function () {
    console.log('destroy not implemented')
}
SGeom.prototype.setDynamicPoint = function () {
    console.log('setDynamicPoint not implemented')
}

SGeom.prototype.setPoints = function (pts) {
    console.log('setPoints not implemented')
}

SGeom.prototype.setData = function (pts, atts) {
    console.log('setData not implemented')
}

SGeom.prototype.setSelection = function (b) {
    if (this._isSelected !== b) {
        this._isSelected = b
        if (b) {
            this.onSelect()
        } else {
            this.onDeSelect()
        }
    }
}

SGeom.prototype.onSelect = function () {
    console.log('need to be overrrided')
}

SGeom.prototype.onDeSelect = function () {
    console.log('need to be overrrided')
}

export { SGeom }
