import { Layer } from './Layer'

class GeoServerWFSLayer extends Layer {
    constructor(name, url, layers, geometry = 'geometry') {
        super(name, '__geomKey')
        this._name = name
        this._url = url
        this.geomField = geometry
        this._layers = layers
        this._viewDistance = 20
    }

    get viewDistance() {
        return this._viewDistance
    }

    set viewDistance(v) {
        if (typeof v === 'number') {
            if (v > 1 && v < 120) {
                this._viewDistance = v
            } else {
                console.warn("view distance can be between 1 - 120")
            }
        } else {
            console.warn('viewDistance can be only number');
        }
    }

    setUserParams(params) {
        this._userParams = params
    }

    setScalable(scalable) {
        super.setScalable(scalable)
        let pano = scalable.getBasePano()
        pano.addEvent(AnkaPanAPI.PanoGLV2.LOCATION_CHANGE, this, this.refreshData)
    }

    cleanAllGeomDataHolderData() {
        this.removeAll()
    }

    refreshData() {
        if (!this.visible) {
            return
        }
        this.cleanAllGeomDataHolderData()
        let dataPromise = this.getData()
        if (dataPromise) {
            dataPromise.then(({ features }) => {
                if (features) {
                    for (let i = 0; i < features.length; i++) {
                        const feature = features[i]
                        let gdh = AnkaScalable.GeomDataHolder.fromGeoJSON(feature)
                        if (gdh) {
                            this.addToLocal(gdh)
                        } else {
                            console.warn('Type could not be found')
                        }
                    }
                }
                this.redraw(false)
            })
        }
    }

    getData() {
        const currentPoint = this.scalable.baseObject.getCurrentPoint()
        if (currentPoint) {
            const { lat, lon } = currentPoint
            const distance = this.viewDistance
            const utils = AnkaPanAPI.Utils
            let east = utils.destinationPoint(lat, lon, distance, 90).lon
            let west = utils.destinationPoint(lat, lon, distance, 270).lon
            let north = utils.destinationPoint(lat, lon, distance, 0).lat
            let south = utils.destinationPoint(lat, lon, distance, 180).lat
            const bbox = `BBOX(${this.geomField},${west},${south},${east},${north})`
            const typeName = this._layers
            let params = {
                service: 'WFS',
                version: '1.0.0',
                request: 'GetFeature',
                outputFormat: 'application/json',
                typeName,
                propertyName: '*',
                CQL_FILTER: bbox
            }

            if (this._userParams) {
                params = Object.assign(params, this._userParams)
            }

            let keyValue = []
            for (const key in params) {
                const val = params[key]
                keyValue.push(`${key}=${val}`)
            }

            const reqUrl = this._url + '?' + keyValue.join('&')
            return AnkaPanAPI.AnkaFetch(reqUrl).then(e => e.json())
        } else {
            const ms = 1000;
            console.log(`Panorama is not found, will refresh in ${ms / 1000}`);
            setTimeout(() => refreshData(), ms)
        }


    }

    // geoJsonToGeoms(json) {
    //     let cloneGeoms = this.getGeomListClone()
    //     this.resetGeomList()
    //     if (json.totalFeatures > 0) {
    //         const { features } = json
    //         for (let i = 0; i < features.length; i++) {
    //             let { geometry, properties, id } = features[i]
    //             const { type } = geometry
    //             let gdh
    //             properties['__geomKey'] = id
    //             if (type === 'Point') {
    //                 let [lon, lat, alt] = geometry.coordinates
    //                 gdh = new AnkaScalable.GeomDataHolder(AnkaScalable.SPoint, [{ lon, lat, alt }], properties)
    //             } else if (type === 'Polygon') {
    //                 let [points] = geometry.coordinates
    //                 const pts = points.map(([lon, lat, alt]) => ({ lon, lat, alt }))
    //                 gdh = new AnkaScalable.GeomDataHolder(AnkaScalable.SPolygon, pts, properties)

    //                 let holes = []
    //                 let coords = geometry.coordinates
    //                 for (let i = 1; i < coords.length; i++) {
    //                     let hole = coords[i]
    //                     holes.push(hole.map(([lon, lat, alt]) => ({ lon, lat, alt })))
    //                 }

    //                 if (holes.length > 0) {
    //                     gdh.holes = holes
    //                 }
    //             } else if (type === 'LineString') {
    //                 let points = geometry.coordinates
    //                 const pts = points.map(([lon, lat, alt]) => ({ lon, lat, alt }))
    //                 gdh = new AnkaScalable.GeomDataHolder(AnkaScalable.SLine, pts, properties)
    //             } else if (type === 'MultiPolygon') {
    //                 let polygons = geometry.coordinates
    //                 let convertedPolygons = []
    //                 for (let i = 0; i < polygons.length; i++) {
    //                     const pdata = polygons[i]
    //                     let [polygon] = pdata
    //                     polygon = polygon.map(([lon, lat, alt]) => ({ lon, lat, alt }))
    //                     convertedPolygons.push(polygon)
    //                 }
    //                 gdh = new AnkaScalable.GeomDataHolder(AnkaScalable.SMultiPolygon, convertedPolygons, properties)
    //             } else if (type === 'MultiLineString') {
    //                 let polygons = geometry.coordinates
    //                 let convertedPolygons = []
    //                 for (let i = 0; i < polygons.length; i++) {
    //                     let line = polygons[i]
    //                     line = line.map(([lon, lat, alt]) => ({ lon, lat, alt }))
    //                     convertedPolygons.push(line)
    //                 }
    //                 gdh = new AnkaScalable.GeomDataHolder(AnkaScalable.SMultiLine, convertedPolygons, properties)
    //             } else {
    //                 console.log(`${type} type not supported`)
    //             }

    //             if (gdh) {
    //                 gdh.setStatus(AnkaScalable.GeomDataHolder.STATUS.COMPLETED)
    //                 // this.addToList(gdh)
    //             }
    //         }
    //     }
    //     this.removeGeomDatas(cloneGeoms)
    // }

    redraw(viaNetwork) {
        this.removeAllChildren()

        if (this.visible) {
            if (viaNetwork) {
                this.refreshData()
            } else {
                super.redraw()
            }
        }
    }
}

GeoServerWFSLayer.TYPES = {
    POINT: 'POINT'
}

export { GeoServerWFSLayer }
