
import { SGeom } from './SGeom'
import { EventMesh } from './EventMesh'
SPolygon.isMultiGeom = false

let vertexPointMaterial = new THREE.PointsMaterial({
    color: 0xffffff,
    size: 5,
    depthWrite: false,
    depthTest: false,
    sizeAttenuation: false
})

function SPolygon(scalable, geomdata) {
    SGeom.apply(this, arguments)
    this.points = []
    this.type = 'Polygon'
    this._lineColor = 0x0000ff
    this.canDraw = true
    this.scalable = scalable
    this.softText = scalable.softText
    this.opacity = 0.7
    this.labelHook = new THREE.Object3D()

    var material = new THREE.LineBasicMaterial({
        color: this._lineColor,
        linewidth: 1,
        depthTest: true,
        transparent: true,
        side: THREE.DoubleSide,
        depthWrite: false
    })

    this.material = material
    var vertexCount = 200

    var geom = new THREE.BufferGeometry()
    var positions = new Float32Array(vertexCount * 3) // 3 vertices per point
    geom.addAttribute('position', new THREE.BufferAttribute(positions, 3))

    this.line = new THREE.Line(geom, material)
    this.line.frustumCulled = false
    this._fillColor = Math.round(Math.random() * 0xffffff)
    var fillMat = new THREE.MeshBasicMaterial({
        color: this._fillColor,
        depthTest: true,
        transparent: true,
        opacity: this.opacity,
        depthWrite: false,
        side: THREE.DoubleSide
    })

    var vpositions = new Float32Array(vertexCount * 3)
    var nomal = new Float32Array(vertexCount * 3)
    var indices = new Uint16Array(vertexCount)

    var fillGeom = new THREE.BufferGeometry()
    fillGeom.addAttribute('position', new THREE.BufferAttribute(vpositions, 3))
    fillGeom.addAttribute('nomal', new THREE.BufferAttribute(nomal, 3))
    fillGeom.setIndex(new THREE.BufferAttribute(indices, 1))

    this.fill = new EventMesh(fillGeom, fillMat)
    this.fill.frustumCulled = false
    this.add(this.fill)

    fillGeom.setDrawRange(0, 3)

    this.add(this.line)
}

SPolygon.prototype = Object.assign(Object.create(SGeom.prototype),
    {
        constructor: SPolygon,

        reset: function () {
            this.lookAt(new THREE.Vector3(0, this.position.y, 0))
        },

        enable: function (panogl, renderScene) {
            if (!this.enabled) {
                this.openGeom = true
                this.panogl = panogl
                var cam = panogl.getMainCamera()
                this.fill.setClickable(true, panogl, null, cam)
                this.fill.addEvent(SGeom.CLICK, this, this.onClick)
                this.fill.addEvent(SGeom.MOUSE_DOWN, this, this.onDown)
            }
        },

        stopDraw: function () {
            this.openGeom = false
            var firstP = this.points[0]
            this.points.push(firstP)
            this.dynamicPoint = null
            this.line.frustumCulled = true
            this.fill.frustumCulled = true
            this.update()
        },

        setDynamicPoint: function (p) {
            if (this.points.length > 0) {
                this.dynamicPoint = { lon: p.lon, lat: p.lat, alt: p.alt }
            }
        },

        setData: function (points, atts, holes) {
            this.attributes = atts
            this.points.length = 0
            var i
            for (i = 0; i < points.length; i++) {
                this.points.push(points[i])
            }

            this.holes = []

            if (holes) {
                for (i = 0; i < holes.length; i++) {
                    var holeAr = []
                    var hole = holes[i]
                    for (var j = 0; j < hole.length; j++) {
                        holeAr.push(hole[j])
                    }
                    this.holes.push(holeAr)
                }
            }

            this.line.frustumCulled = false
            this.fill.frustumCulled = false
        },

        calculateArea: function (triangles) {
            if (!triangles) {
                return
            }

            let Utils = AnkaPanAPI.Utils
            var totalS = 0
            for (var i = 0; i < triangles.length; i++) {
                var tri = triangles[i]
                var points = tri.getPoints()
                var a = points[0]
                var b = points[1]
                var c = points[2]

                var aalt = isNaN(a.alt) || a.alt === undefined || a.alt == null ? 0 : a.alt
                var balt = isNaN(b.alt) || b.alt === undefined || b.alt == null ? 0 : b.alt
                var calt = isNaN(c.alt) || c.alt === undefined || c.alt == null ? 0 : c.alt

                var AB = Utils.haversine(a.lat, b.lat, a.lon, b.lon)
                var ABC = Math.sqrt((AB * AB) + Math.pow(aalt - balt, 2))

                var BC = Utils.haversine(b.lat, c.lat, b.lon, c.lon)
                var BCD = Math.sqrt((BC * BC) + Math.pow(balt - calt, 2))

                var CA = Utils.haversine(c.lat, a.lat, c.lon, a.lon)
                var CAD = Math.sqrt((CA * CA) + Math.pow(calt - aalt, 2))

                var S = Utils.heron(ABC, BCD, CAD)
                totalS += S
            }
            var mul = Math.round(totalS * 1000) / 1000
            this.areaSize = mul + 'm²'
            this.setLabel()
        },

        setLabel: function () {
            if (!this.openGeom) {
                var c = this.getCentroid()
                var cam = this.panogl.getMainCamera()
                this.labelHook.position.set(c.x, c.y, c.z)
                this.add(this.labelHook)
                this._commonGeom.attributes['_areaSize'] = this.areaSize
                this.softText && this.softText.addObject3D(this.labelHook, cam, this.getLabelText())
            }
        },
        getCentroid: function () {
            var sp = this.points

            var minX = 500000000
            var minY = 500000000
            var minZ = 500000000
            // var  = -500000000;
            var maxX = -500000000
            var maxY = -500000000
            var maxZ = -500000000

            for (var i = 0; i < sp.length; i++) {
                var p = sp[i]
                var pp = this.scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)

                if (pp.x < minX) { minX = pp.x }

                if (pp.x > maxX) { maxX = pp.x }

                if (pp.z < minZ) { minZ = pp.z }

                if (pp.z > maxZ) { maxZ = pp.z }

                if (pp.y < minY) { minY = pp.y }

                if (pp.y > maxY) { maxY = pp.y }
            }

            var x = minX + ((maxX - minX) / 2)
            var z = minZ + ((maxZ - minZ) / 2)
            var y = minY + ((maxY - minY) / 2)

            return { x: x, y: y, z: z }
        },
        onClick: function (e) {
            e.currentTarget = this
            this.throwEvent(e)
        },

        addPoint: function (lon, lat, alt) {
            this.points.push({ lon: lon, lat: lat, alt: alt })
        },

        onSelect: function () {
            this.line.material.color = new THREE.Color(0, 0.5, 1)
            this.fill.material.color = new THREE.Color(0.1, 1, 1)
            this.fill.material.opacity = 1
        },

        onDeSelect: function () {
            if (this.__layer) {
                this.updateStyle()
            } else {
                this.line.material.color = new THREE.Color(this._lineColor)
                this.fill.material.color = new THREE.Color(this._fillColor)
                this.fill.material.opacity = this.opacity
            }
        },
        updateStyle: function () {
            if (this.__layer) {
                var style = this.__layer.getStyle(this)
                this.fill.material.color = new THREE.Color(style.fillColor)
                this.fill.material.opacity = style.fillOpacity * this.getOpacity()
                this.line.material.color = new THREE.Color(style.lineColor)
                this.line.material.opacity = style.lineOpacity * this.getOpacity()
            }
        },

        removeAnchoPoints: function () {
            let vertexPoints = this.vertexPoints
            if (vertexPoints) {
                this.remove(vertexPoints)
                vertexPoints.geometry.dispose()
            }
        },

        update: function () {
            var positionPoints = this.getPositionedPoints()
            var positionedHolePoints = this.getPositionedHolePoints()
            var boundsData = this.getBounds(positionPoints)
            var vPoints = this.updateLineVertices(positionPoints, boundsData, positionedHolePoints)
            this.position.set(0, 0, 0)

            let vertices = positionPoints.reduce((acc, { x, y, z }) => {
                acc.push(x, y, z)
                return acc
            }, [])

            this.removeAnchoPoints()
            if (vertices.length > 0) {
                let geom = new THREE.BufferGeometry()
                geom.addAttribute('position', new THREE.BufferAttribute(new Float32Array(vertices), 3))
                let vertexPoints = new THREE.Points(geom, vertexPointMaterial)
                this.add(vertexPoints)
                this.vertexPoints = vertexPoints
            }

            if (vPoints.length < 3) return

            var p1 = vPoints[0]
            var p2 = vPoints[1]
            var p3 = vPoints[2]

            var f = AnkaPanAPI.Utils.GetNormal(p1, p2, p3)
            var face = new THREE.Vector3(f.x, f.y, f.z)
            face.normalize()

            var cleanCo
            var cleanHoles
            var isOnGround
            var i
            if (Math.abs(face.z) > Math.abs(face.y) && Math.abs(face.z) > Math.abs(face.x)) {
                isOnGround = true
                cleanCo = this.getNonReapetedPoints(vPoints, 'x', 'y')

                if (this.holes && this.holes.length > 0) {
                    cleanHoles = []
                    for (i = 0; i < positionedHolePoints.length; i++) {
                        var php = positionedHolePoints[i]
                        let cphp = this.getNonReapetedPoints(php, 'x', 'z')
                        if (cphp) {
                            for (var j = 0; j < cphp.length; j++) {
                                cphp[j]._x = cphp[j].x
                                cphp[j]._y = cphp[j].y
                                cphp[j]._z = cphp[j].z

                                cphp[j].y = cphp[j]._z
                                cphp[j].z = cphp[j]._y
                            }
                        }
                        cleanHoles.push(cphp)
                    }
                }
            } else {
                isOnGround = false
                cleanCo = this.getNonReapetedPoints(vPoints, 'x', 'z')

                if (this.holes && this.holes.length > 0) {
                    cleanHoles = []
                    for (i = 0; i < positionedHolePoints.length; i++) {
                        let cphp = this.getNonReapetedPoints(positionedHolePoints[i], 'x', 'y')
                        cleanHoles.push(cphp)
                    }
                }

                for (i = 0; i < cleanCo.length; i++) {
                    cleanCo[i]._x = cleanCo[i].x
                    cleanCo[i]._y = cleanCo[i].y
                    cleanCo[i]._z = cleanCo[i].z

                    cleanCo[i].y = cleanCo[i]._z
                    cleanCo[i].z = cleanCo[i]._y
                }
            }

            if (cleanCo.length > 2) {
                var swctx = new poly2tri.SweepContext(cleanCo)

                if (cleanHoles) {
                    for (i = 0; i < cleanHoles.length; i++) {
                        swctx.addHole(cleanHoles[i])
                    }
                }

                var tri
                try {
                    tri = swctx.triangulate()
                } catch (e) {
                    this.canDraw = false
                    console.log(e)
                    return
                }

                this.canDraw = true
                var tar = tri.getTriangles()
                this.updateFillVertices(tar, boundsData, isOnGround)
            } else {
                // console.log('not enough points')
            }

            this.calculateArea(this.triangles)
            this.setLabel()
        }, // update end

        getNonReapetedPoints: function (contour, key1, key2) {
            var repeat = {}
            var cleanCo = []
            for (var i = 0; i < contour.length; i++) {
                var id = contour[i][key1] + '_' + contour[i][key2]
                if (!repeat[id]) {
                    repeat[id] = 1
                    cleanCo.push(contour[i])
                } else {

                }
            }
            return cleanCo
        },

        getBounds: function (contour) {
            var mn = Number.MIN_SAFE_INTEGER
            var maxX = mn
            var maxY = mn
            var maxZ = mn

            var mx = Number.MAX_SAFE_INTEGER
            var minX = mx
            var minY = mx
            var minZ = mx

            for (var i = 0; i < contour.length; i++) {
                if (contour[i].x < minX) {
                    minX = contour[i].x
                }

                if (contour[i].y < minY) {
                    minY = contour[i].y
                }

                if (contour[i].z < minZ) {
                    minZ = contour[i].z
                }

                if (contour[i].x > maxX) {
                    maxX = contour[i].x
                }

                if (contour[i].y > maxY) {
                    maxY = contour[i].y
                }

                if (contour[i].z > maxZ) {
                    maxZ = contour[i].z
                }
            }

            return {
                maxX: maxX,
                maxY: maxY,
                maxZ: maxZ,
                minX: minX,
                minY: minY,
                minZ: minZ,
                halfX: (maxX - minX) * 0.5,
                halfY: (maxY - minY) * 0.5,
                halfZ: (maxZ - minZ) * 0.5
            }
        },

        updateFillVertices: function (tar, boundData, isOnGround) {
            var fgeom = this.fill.geometry
            var vertices = fgeom.attributes.position.array
            var indices = fgeom.index.array
            this.triangles = tar
            var index = 0
            var indicesIndex = 0
            var ind = 0
            for (var i = 0; i < tar.length; i++) {
                var pts = tar[i]
                pts = pts.getPoints()

                for (var j = 0; j < pts.length; j++) {
                    var p = pts[j]

                    if (isOnGround) {
                        vertices[index++] = p.x
                        vertices[index++] = p.z
                        vertices[index++] = p.y
                    } else {
                        vertices[index++] = p.x
                        vertices[index++] = p.y
                        vertices[index++] = p.z
                    }

                    var gi = ((i + 1) * 3)
                    ind = gi - (j + 1)

                    indices[indicesIndex++] = ind
                }
            }
            fgeom.setDrawRange(0, index / 3)
            fgeom.attributes.position.needsUpdate = true
            fgeom.index.needsUpdate = true
        },

        updateLineVertices: function (positionPoints, boundData, positionedHolePoints) {
            var vertexCount = positionPoints.length
            var geom = this.line.geometry
            var par = geom.attributes.position.array

            if (vertexCount !== par.length / 3) {
                this.line.geometry.dispose()
                if (this.line.parent) {
                    this.line.parent.remove(this.line)
                }

                geom = new THREE.BufferGeometry()
                var positions = new Float32Array(vertexCount * 3) // 3 vertices per point
                geom.addAttribute('position', new THREE.BufferAttribute(positions, 3))

                par = geom.attributes.position.array

                this.line = new THREE.Line(geom, this.material)
                this.line.frustumCulled = false
                this.add(this.line)
            }

            var vPoints = []
            var index = 0
            for (var i = 0; i < positionPoints.length; i++) {
                var pp = positionPoints[i]
                par[index++] = pp.x
                par[index++] = pp.y
                par[index++] = pp.z
                vPoints.push({ x: pp.x, y: pp.z, z: pp.y, lon: pp.lon, lat: pp.lat, alt: pp.alt })
            }

            geom.setDrawRange(0, positionPoints.length)
            geom.attributes.position.needsUpdate = true

            return vPoints
        },

        getPositionedPoints: function () {
            var points = []
            var sp = this.points
            var p
            var pp
            for (var i = 0; i < sp.length; i++) {
                p = sp[i]
                pp = this.scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)
                pp.lat = p.lat
                pp.lon = p.lon
                pp.alt = p.alt
                points.push(pp)
            }

            if (this.dynamicPoint) {
                p = this.dynamicPoint
                pp = this.scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)
                pp.lat = p.lat
                pp.lon = p.lon
                pp.alt = p.alt
                points.push(pp)
            }
            return points
        },

        getPositionedHolePoints: function () {
            if (!this.holes || this.holes.length === 0) return

            var fullHoles = []
            for (var i = 0; i < this.holes.length; i++) {
                var holePoints = []
                var sp = this.holes[i]
                for (var j = 0; j < sp.length; j++) {
                    var p = sp[j]
                    var pp = this.scalable.calculatePointPositionFromLonLatAlt(p.lon, p.lat, p.alt)
                    pp.lat = p.lat
                    pp.lon = p.lon
                    pp.alt = p.alt
                    holePoints.push(pp)
                }
                fullHoles.push(holePoints)
            }

            return fullHoles
        },

        disableAll: function () {
            if (this.parent) {
                this.parent.remove(this)
            }
            this.enabled = false
        },

        destroy: function () {
            this.disableAll()

            let line = this.line
            if (line) {
                if (line.geometry) {
                    line.geometry.dispose()
                }

                if (line.material) {
                    line.material.dispose()
                }
            }

            let fill = this.fill
            if (fill) {
                fill.setClickable(false)
                if (fill.geometry) {
                    fill.geometry.dispose()
                }

                if (fill.material) {
                    fill.material.dispose()
                }
            }

            if (this.labelHook) {
                this.softText && this.softText.removeObject3D(this.labelHook)
                this.softText = null
                this.labelHook = null
            }
        }

    })

export { SPolygon }
