
let AnkaPanAPI
let PanoGL
let AssetManager
let Base
if (window.AnkaPanAPI) {
    AnkaPanAPI = window.AnkaPanAPI
    PanoGL = AnkaPanAPI.PanoGL
    Base = AnkaPanAPI.Base
    AssetManager = AnkaPanAPI.AssetManager
} else {
    console.error('AnkaPanAPI could not be found')
}

let Vector3
let Geometry
let PointsMaterial
let PlaneBufferGeometry
let Points
let Texture
let LinearFilter
let Color
if (window.THREE) {
    Vector3 = THREE.Vector3
    Geometry = THREE.Geometry
    PointsMaterial = THREE.PointsMaterial
    PlaneBufferGeometry = THREE.PlaneBufferGeometry
    Texture = THREE.Texture
    Points = THREE.Points
    LinearFilter = THREE.LinearFilter
    Color = THREE.Color
} else {
    console.error('THREE could not be found')
}

export {
    AnkaPanAPI,
    AssetManager,
    Geometry,
    PointsMaterial,
    Points,
    Vector3,
    Texture,
    Color,
    LinearFilter,
    PlaneBufferGeometry,
    Base,
    PanoGL
}
